import { CHANGE_PAGINA } from '../actions/types';

const INITIAL_STATE = 1;

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case CHANGE_PAGINA: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

import {
  RICALCOLO,
  CHANGE_VALORE,
  CHANGE_PAGINA,
  RESET
} from './types'

export function ricalcolo() {
  return {
    type: RICALCOLO,
    payload: null,
  }
}

export function changeValore(oggetto) {
  return {
    type: CHANGE_VALORE,
    payload: oggetto,
  }
}

export function changePagina(pagina) {
  return {
    type: CHANGE_PAGINA,
    payload: pagina,
  }
}

export function reset() {
  return {
    type: RESET,
    payload: null,
  }
}

// TASSI
export const TASSO_30 = 4.19
export const TASSO_25 = 4.68
export const TASSO_20 = 5.45
export const TASSO_15 = 6.79
export const TASSO_10 = 9.56
export const TASSO_7 = 18.0

// MUTUO BANCARIO
export const MUTUO_BANCARIO_ANTICIPO = 0.2 //0.36

// MUTUO GRATIS
export const MUTUO_GRATIS_ANTICIPO = 0.3

// MAXI RATA FINALE
export const MAXI_RATA_FINALE_ANTICIPO = 0.5

// ANTICIPO ZERO
export const ANTICIPO_ZERO_ANTICIPO = 0

// ANTICIPO ZERO PLUS
export const ANTICIPO_ZERO_PLUS_ANTICIPO_7_ANNI = 20000

// LEASING
export const LEASING_ANTICIPO = 0.25
export const LEASING_RISCATTO = 0.2

// RENT TO BUY
export const RENT_TO_BUY_CANONE_SU_IMPORTO = 0.02
export const RENT_TO_BUY_VALORE_AFFITTO = 1 / 3

// AFFITTO RISCATTO
export const AFFITTO_RISCATTO_ANTICIPO = 0.1
export const AFFITTO_RISCATTO_CANONE_SU_IMPORTO = 0.0065

// MUTUO 2020
export const MUTUO_2020_FASCE = [
  { da: 0, a: 119000, bonusFisso: 10000 },
  { da: 119000.01, a: 149000, bonusVariabile: 0.085 },
  { da: 149000.01, a: 169000, bonusVariabile: 0.08 },
  { da: 169000.01, a: Infinity, bonusVariabile: 0.07 },
]

// COLORI
export const COLORE_MUTUO_BANCARIO = '#3e9d03'
export const COLORE_MUTUO_BANCARIO_BG = '#c7f7a9'
export const COLORE_MUTUO_GRATIS = '#0363a9'
export const COLORE_MUTUO_GRATIS_BG = '#abdbfd'
export const COLORE_MAXI_RATA_FINALE = '#8109bd'
export const COLORE_MAXI_RATA_FINALE_BG = '#dfadf7'
export const COLORE_ANTICIPO_ZERO = '#00a6e0'
export const COLORE_ANTICIPO_ZERO_BG = '#c0ebf8'
export const COLORE_ANTICIPO_ZERO_PLUS = '#84b903'
export const COLORE_ANTICIPO_ZERO_PLUS_BG = '#e5f5b5'
export const COLORE_LEASING = '#e17b03'
export const COLORE_LEASING_BG = '#fdd8ad'
export const COLORE_RENT_TO_BUY = '#ca1a47'
export const COLORE_RENT_TO_BUY_BG = '#f7bdcc'
export const COLORE_AFFITTO_RISCATTO = '#271aca'
export const COLORE_AFFITTO_RISCATTO_BG = '#b8b3f5'
export const COLORE_MUTUO_GRATIS_2020 = '#047b9f'
export const COLORE_MUTUO_GRATIS_2020_BG = '#ace9fb'
export const COLORE_MUTUO_AGEVOLATO_2020 = '#9c7e26'
export const COLORE_MUTUO_AGEVOLATO_2020_BG = '#efeacc'
export const COLORE_INTERESSI_GRATIS = '#059e9e'
export const COLORE_INTERESSI_GRATIS_BG = '#d2f0e6'

import React from 'react'
import ReactDOM from 'react-dom'
import numeral from 'numeral'
import 'bootstrap/dist/css/bootstrap.css'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'

import './style.css'

import App from './App'
import reducers from './reducers'
//import * as serviceWorker from './serviceWorker'

numeral.register('locale', 'it', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'mila',
    million: 'mil',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return 'º'
  },
  currency: {
    symbol: '€',
  },
})

numeral.locale('it')

const createStoreWithMiddleware = applyMiddleware()(createStore)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={createStoreWithMiddleware(reducers)}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()

import { RICALCOLO, CHANGE_VALORE, RESET } from '../actions/types'

import {
  TASSO_30,
  TASSO_25,
  TASSO_20,
  TASSO_15,
  TASSO_10,
  // TASSO_7,
  MUTUO_BANCARIO_ANTICIPO,
  MUTUO_GRATIS_ANTICIPO,
  // MAXI_RATA_FINALE_ANTICIPO,
  ANTICIPO_ZERO_ANTICIPO,
  // ANTICIPO_ZERO_PLUS_ANTICIPO_7_ANNI,
  // LEASING_ANTICIPO,
  // LEASING_RISCATTO,
  // RENT_TO_BUY_CANONE_SU_IMPORTO,
  // RENT_TO_BUY_VALORE_AFFITTO,
  // AFFITTO_RISCATTO_ANTICIPO,
  // AFFITTO_RISCATTO_CANONE_SU_IMPORTO,
  // MUTUO_2020_FASCE,
  COLORE_MUTUO_BANCARIO,
  COLORE_MUTUO_GRATIS,
  // COLORE_MAXI_RATA_FINALE,
  COLORE_ANTICIPO_ZERO,
  // COLORE_ANTICIPO_ZERO_PLUS,
  // COLORE_LEASING,
  // COLORE_RENT_TO_BUY,
  // COLORE_AFFITTO_RISCATTO,
  COLORE_MUTUO_GRATIS_2020,
  COLORE_INTERESSI_GRATIS,
  COLORE_MUTUO_AGEVOLATO_2020,
  COLORE_MUTUO_BANCARIO_BG,
  COLORE_MUTUO_GRATIS_BG,
  // COLORE_MAXI_RATA_FINALE_BG,
  COLORE_ANTICIPO_ZERO_BG,
  // COLORE_ANTICIPO_ZERO_PLUS_BG,
  // COLORE_LEASING_BG,
  // COLORE_RENT_TO_BUY_BG,
  // COLORE_AFFITTO_RISCATTO_BG,
  COLORE_MUTUO_GRATIS_2020_BG,
  COLORE_INTERESSI_GRATIS_BG,
  COLORE_MUTUO_AGEVOLATO_2020_BG,
} from './parametri_calcolo'

const INITIAL_STATE = {
  valoreImmobile: '',
  anticipoCustom: '',
  rataIniziale: '',
  importoVacanze: '',
  incassoAffitto: '',
  valoreVendita: '',
  scontoInteressiGratis: '10',
  scelta: '',
  anniMutuo: 30,
  strumenti: [
    {
      strumento: 'Pagamento relax', //'Mutuo bancario',
      colore: COLORE_MUTUO_BANCARIO,
      coloreBg: COLORE_MUTUO_BANCARIO_BG,
      pagina: 2,
      rate: [
        { nome: 'Anticipo', valore: '' },
        //{ nome: 'Saldo', valore: '' },
        { nome: '30 anni', valore: '' },
        { nome: '25 anni', valore: '' },
        { nome: '20 anni', valore: '' },
        { nome: '15 anni', valore: '' },
        { nome: '10 anni', valore: '' },
      ],
    },
    {
      strumento: 'Su misura',
      colore: COLORE_MUTUO_BANCARIO,
      coloreBg: COLORE_MUTUO_BANCARIO_BG,
      pagina: 1,
      rate: [
        { nome: 'Anticipo', valore: '' },
        //{ nome: 'Saldo', valore: '' },
        { nome: '30 anni', valore: '' },
        { nome: '25 anni', valore: '' },
        { nome: '20 anni', valore: '' },
        { nome: '15 anni', valore: '' },
        { nome: '10 anni', valore: '' },
      ],
    },
    // {
    //   strumento: 'Leasing',
    //   colore: COLORE_LEASING,
    //   coloreBg: COLORE_LEASING_BG,
    //   pagina: 2,
    //   rate: [
    //     { nome: 'Anticipo', valore: '' },
    //     { nome: 'Riscatto', valore: '' },
    //     { nome: '20 anni', valore: '' },
    //     { nome: '15 anni', valore: '' },
    //     { nome: '10 anni', valore: '' },
    //     { nome: '5 anni', valore: '' },
    //     //{ nome: 'Leasing 3', valore: '' },
    //   ],
    // },
    {
      strumento: 'Anticipo zero',
      colore: COLORE_ANTICIPO_ZERO,
      coloreBg: COLORE_ANTICIPO_ZERO_BG,
      pagina: 2,
      rate: [
        { nome: 'Anticipo', valore: 0 },
        //{ nome: 'Saldo', valore: '' },
        { nome: '30 anni', valore: '' },
        { nome: '25 anni', valore: '' },
        { nome: '20 anni', valore: '' },
        { nome: '15 anni', valore: '' },
        { nome: '10 anni', valore: '' },
      ],
    },
    // {
    //   strumento: 'Anticipo zero+',
    //   colore: COLORE_ANTICIPO_ZERO_PLUS,
    //   coloreBg: COLORE_ANTICIPO_ZERO_PLUS_BG,
    //   pagina: 2,
    //   rate: [
    //     { nome: '+84 mesi', valore: '' },
    //     //{ nome: 'Saldo', valore: '' },
    //     { nome: '30 anni', valore: '' },
    //     { nome: '25 anni', valore: '' },
    //     { nome: '20 anni', valore: '' },
    //     { nome: '15 anni', valore: '' },
    //     { nome: '10 anni', valore: '' },
    //   ],
    // },
    {
      strumento: 'Dilazioni senza banca',
      colore: COLORE_MUTUO_GRATIS,
      coloreBg: COLORE_MUTUO_GRATIS_BG,
      pagina: 2,
      rate: [
        { nome: 'Anticipo', valore: '' },
        //{ nome: 'Saldo', valore: '' },
        { nome: '10 anni', valore: '' },
        { nome: '8 anni', valore: '' },
        { nome: '6 anni', valore: '' },
        { nome: '4 anni', valore: '' },
        { nome: '2 anni', valore: '' },
      ],
    },
    // {
    //   strumento: 'Maxi rata finale',
    //   colore: COLORE_MAXI_RATA_FINALE,
    //   coloreBg: COLORE_MAXI_RATA_FINALE_BG,
    //   pagina: 2,
    //   rate: [
    //     { nome: 'Anticipo', valore: '' },
    //     { nome: '2 anni', valore: '' },
    //     { nome: '30 anni', valore: '' },
    //     { nome: '25 anni', valore: '' },
    //     { nome: '20 anni', valore: '' },
    //     { nome: '15 anni', valore: '' },
    //     // { nome: '10 anni', valore: '' },
    //   ],
    // },
    // {
    //   strumento: 'Rent to buy',
    //   colore: COLORE_RENT_TO_BUY,
    //   coloreBg: COLORE_RENT_TO_BUY_BG,
    //   pagina: 2,
    //   rate: [
    //     { nome: 'Mensile', valore: '' },
    //     { nome: 'Saldo 1 anno', valore: '' },
    //     { nome: 'Saldo 2 anni', valore: '' },
    //     { nome: 'Saldo 3 anni', valore: '' },
    //     { nome: 'Saldo 4 anni', valore: '' },
    //     { nome: 'Saldo 5 anni', valore: '' },
    //     //{ nome: 'Saldo anni 6', valore: '' },
    //   ],
    // },
    // {
    //   strumento: 'Affitto riscatto',
    //   colore: COLORE_AFFITTO_RISCATTO,
    //   coloreBg: COLORE_AFFITTO_RISCATTO_BG,
    //   pagina: 2,
    //   rate: [
    //     { nome: 'Mensile', valore: '' },
    //     { nome: '1° blocco', valore: '' },
    //     { nome: 'Saldo 1 anno', valore: '' },
    //     { nome: 'Saldo 2 anni', valore: '' },
    //     { nome: 'Saldo 3 anni', valore: '' },
    //     { nome: '2° blocco', valore: '' },
    //     //{ nome: '2° saldo anni 2', valore: '' },
    //   ],
    // },
    {
      strumento: 'Mutuo rate gratis',
      colore: COLORE_MUTUO_GRATIS_2020,
      coloreBg: COLORE_MUTUO_GRATIS_2020_BG,
      pagina: 3,
      rate: [
        { nome: 'Anticipo', valore: '' },
        { nome: '30 anni', valore: '' },
        { nome: '25 anni', valore: '' },
        { nome: '20 anni', valore: '' },
        { nome: '15 anni', valore: '' },
        { nome: '10 anni', valore: '' },
      ],
      bonusMesi: [{ valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }],
      bonusAnni: [{ valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }],
    },
    {
      strumento: 'Mutuo zero pensieri', //'Mutuo agevolato',
      colore: COLORE_MUTUO_AGEVOLATO_2020,
      coloreBg: COLORE_MUTUO_AGEVOLATO_2020_BG,
      pagina: 5,
      rate: [
        { nome: 'Anticipo', valore: '' },
        { nome: 'Rata iniziale', valore: '' },
        { nome: '30 anni', valore: '' },
        { nome: '25 anni', valore: '' },
        { nome: '20 anni', valore: '' },
        { nome: '15 anni', valore: '' },
        { nome: '10 anni', valore: '' },
      ],
      // bonusMesi: [{ valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }],
      // bonusAnni: [{ valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }, { valore: '' }],
    },
    {
      strumento: 'Mutuo senza interessi', //'Mutuo gratis 2.0 - Interessi gratis',
      colore: COLORE_INTERESSI_GRATIS,
      coloreBg: COLORE_INTERESSI_GRATIS_BG,
      pagina: 4,
      rate: [
        { nome: 'Anticipo', valore: '' },
        { nome: '30 anni', valore: '' },
        { nome: '25 anni', valore: '' },
        { nome: '20 anni', valore: '' },
        { nome: '15 anni', valore: '' },
        { nome: '10 anni', valore: '' },
      ],
      rataAgevolata: [
        { valore: '' },
        { valore: '' },
        { valore: '' },
        { valore: '' },
        { valore: '' },
      ],
    },
  ],
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RICALCOLO: {
      const valoreImmobile = state.valoreImmobile && state.valoreImmobile.replace('.', '')
      const anticipoCustom = state.anticipoCustom && state.anticipoCustom.replace('.', '')
      // const rataIniziale = state.rataIniziale && state.rataIniziale.replace('.', '')
      const scontoInteressiGratis =
        state.scontoInteressiGratis && state.scontoInteressiGratis.replace('.', '')

      // MUTUO BANCARIO
      const MutuoBancarioAnticipo = valoreImmobile * MUTUO_BANCARIO_ANTICIPO
      const MutuoBancarioSaldo = valoreImmobile - MutuoBancarioAnticipo
      const MutuoBancario30 = (MutuoBancarioSaldo * TASSO_30) / 1000
      const MutuoBancario25 = (MutuoBancarioSaldo * TASSO_25) / 1000
      const MutuoBancario20 = (MutuoBancarioSaldo * TASSO_20) / 1000
      const MutuoBancario15 = (MutuoBancarioSaldo * TASSO_15) / 1000
      const MutuoBancario10 = (MutuoBancarioSaldo * TASSO_10) / 1000

      // MUTUO BANCARIO PERSONALIZZATO
      const MutuoBancarioPersonalizzatoAnticipo = anticipoCustom
        ? anticipoCustom
        : valoreImmobile * MUTUO_BANCARIO_ANTICIPO
      const MutuoBancarioPersonalizzatoSaldo = valoreImmobile - MutuoBancarioPersonalizzatoAnticipo
      const MutuoBancarioPersonalizzato30 = (MutuoBancarioPersonalizzatoSaldo * TASSO_30) / 1000
      const MutuoBancarioPersonalizzato25 = (MutuoBancarioPersonalizzatoSaldo * TASSO_25) / 1000
      const MutuoBancarioPersonalizzato20 = (MutuoBancarioPersonalizzatoSaldo * TASSO_20) / 1000
      const MutuoBancarioPersonalizzato15 = (MutuoBancarioPersonalizzatoSaldo * TASSO_15) / 1000
      const MutuoBancarioPersonalizzato10 = (MutuoBancarioPersonalizzatoSaldo * TASSO_10) / 1000

      // MUTUO GRATIS
      const MutuoGratisAnticipo = valoreImmobile * MUTUO_GRATIS_ANTICIPO
      const MutuoGratisSaldo = valoreImmobile - MutuoGratisAnticipo
      const MutuoGratis10 = MutuoGratisSaldo / (10 * 12)
      const MutuoGratis8 = MutuoGratisSaldo / (8 * 12)
      const MutuoGratis6 = MutuoGratisSaldo / (6 * 12)
      const MutuoGratis4 = MutuoGratisSaldo / (4 * 12)
      const MutuoGratis2 = MutuoGratisSaldo / (2 * 12)

      // MAXI RATA FINALE
      // const MaxiRataFinaleAnticipo = valoreImmobile * MAXI_RATA_FINALE_ANTICIPO
      // const MaxiRataFinaleSaldo = valoreImmobile - MaxiRataFinaleAnticipo
      // const MaxiRataFinale30 = (MaxiRataFinaleSaldo * TASSO_30) / 1000
      // const MaxiRataFinale25 = (MaxiRataFinaleSaldo * TASSO_25) / 1000
      // const MaxiRataFinale20 = (MaxiRataFinaleSaldo * TASSO_20) / 1000
      // const MaxiRataFinale15 = (MaxiRataFinaleSaldo * TASSO_15) / 1000
      // const MaxiRataFinale10 = (MaxiRataFinaleSaldo * TASSO_10) / 1000

      // ANTICIPO ZERO
      const AnticipoZeroAnticipo = valoreImmobile * ANTICIPO_ZERO_ANTICIPO
      const AnticipoZeroSaldo = valoreImmobile - AnticipoZeroAnticipo
      const AnticipoZero30 = (AnticipoZeroSaldo * TASSO_30) / 1000
      const AnticipoZero25 = (AnticipoZeroSaldo * TASSO_25) / 1000
      const AnticipoZero20 = (AnticipoZeroSaldo * TASSO_20) / 1000
      const AnticipoZero15 = (AnticipoZeroSaldo * TASSO_15) / 1000
      const AnticipoZero10 = (AnticipoZeroSaldo * TASSO_10) / 1000

      // ANTICIPO ZERO+
      // const AnticipoZeroPlusAnticipo84 =
      //   (ANTICIPO_ZERO_PLUS_ANTICIPO_7_ANNI * TASSO_7) / 1000
      // const AnticipoZeroPlusSaldo = valoreImmobile - ANTICIPO_ZERO_PLUS_ANTICIPO_7_ANNI
      // const AnticipoZeroPlus30 = (AnticipoZeroPlusSaldo * TASSO_30) / 1000
      // const AnticipoZeroPlus25 = (AnticipoZeroPlusSaldo * TASSO_25) / 1000
      // const AnticipoZeroPlus20 = (AnticipoZeroPlusSaldo * TASSO_20) / 1000
      // const AnticipoZeroPlus15 = (AnticipoZeroPlusSaldo * TASSO_15) / 1000
      // const AnticipoZeroPlus10 = (AnticipoZeroPlusSaldo * TASSO_10) / 1000

      // LEASING
      // const LeasingAnticipo = valoreImmobile * LEASING_ANTICIPO
      // const LeasingRiscatto = valoreImmobile * LEASING_RISCATTO
      // const LeasingSaldo = valoreImmobile - LeasingAnticipo - LeasingRiscatto
      // const Leasing30 = (LeasingSaldo * TASSO_30) / 1000
      // const Leasing25 = (LeasingSaldo * TASSO_25) / 1000
      // const Leasing20 = (LeasingSaldo * TASSO_20) / 1000
      // const Leasing15 = (LeasingSaldo * TASSO_15) / 1000
      // const Leasing10 = (LeasingSaldo * TASSO_10) / 1000

      // RENT TO BUT
      // const RentToBuyCanone = valoreImmobile * RENT_TO_BUY_CANONE_SU_IMPORTO
      // const RentToBuySaldo1 = valoreImmobile - RentToBuyCanone * 1 * 12 * RENT_TO_BUY_VALORE_AFFITTO
      // const RentToBuySaldo2 = valoreImmobile - RentToBuyCanone * 2 * 12 * RENT_TO_BUY_VALORE_AFFITTO
      // const RentToBuySaldo3 = valoreImmobile - RentToBuyCanone * 3 * 12 * RENT_TO_BUY_VALORE_AFFITTO
      // const RentToBuySaldo4 = valoreImmobile - RentToBuyCanone * 4 * 12 * RENT_TO_BUY_VALORE_AFFITTO
      // const RentToBuySaldo5 = valoreImmobile - RentToBuyCanone * 5 * 12 * RENT_TO_BUY_VALORE_AFFITTO
      // const RentToBuySaldo6 =
      //   valoreImmobile - RentToBuyCanone * 6 * 12 * RENT_TO_BUY_VALORE_AFFITTO

      // AFFITTO RISCATTO
      // const AffittoRiscattoBlocco1 = valoreImmobile * AFFITTO_RISCATTO_ANTICIPO
      // const AffittoRiscattoCanone = valoreImmobile * AFFITTO_RISCATTO_CANONE_SU_IMPORTO
      // const AffittoRiscattoSaldo1 =
      //   valoreImmobile - AffittoRiscattoBlocco1 - AffittoRiscattoCanone * 1 * 12
      // const AffittoRiscattoSaldo2 =
      //   valoreImmobile - AffittoRiscattoBlocco1 - AffittoRiscattoCanone * 2 * 12
      // const AffittoRiscattoSaldo3 =
      //   valoreImmobile - AffittoRiscattoBlocco1 - AffittoRiscattoCanone * 3 * 12
      // const AffittoRiscattoBlocco2 = valoreImmobile * AFFITTO_RISCATTO_ANTICIPO
      //const AffittoRiscattoSaldo4 = valoreImmobile - AffittoRiscattoBlocco2 - (AffittoRiscattoCanone * 4 * 12);

      // MUTUO GRATIS 2020
      const MutuoGratis2020Anticipo = anticipoCustom
        ? anticipoCustom
        : valoreImmobile * MUTUO_BANCARIO_ANTICIPO
      const MutuoGratis2020_30 = (MutuoBancarioPersonalizzatoSaldo * TASSO_30) / 1000
      const MutuoGratis2020_25 = (MutuoBancarioPersonalizzatoSaldo * TASSO_25) / 1000
      const MutuoGratis2020_20 = (MutuoBancarioPersonalizzatoSaldo * TASSO_20) / 1000
      const MutuoGratis2020_15 = (MutuoBancarioPersonalizzatoSaldo * TASSO_15) / 1000
      const MutuoGratis2020_10 = (MutuoBancarioPersonalizzatoSaldo * TASSO_10) / 1000
      const MutuoGratis2020_30_BonusMesi = MutuoGratis2020_30
        ? Math.round((scontoInteressiGratis * 1000) / MutuoGratis2020_30)
        : 0
      const MutuoGratis2020_25_BonusMesi = MutuoGratis2020_25
        ? Math.round((scontoInteressiGratis * 1000) / MutuoGratis2020_25)
        : 0
      const MutuoGratis2020_20_BonusMesi = MutuoGratis2020_20
        ? Math.round((scontoInteressiGratis * 1000) / MutuoGratis2020_20)
        : 0
      const MutuoGratis2020_15_BonusMesi = MutuoGratis2020_15
        ? Math.round((scontoInteressiGratis * 1000) / MutuoGratis2020_15)
        : 0
      const MutuoGratis2020_10_BonusMesi = MutuoGratis2020_10
        ? Math.round((scontoInteressiGratis * 1000) / MutuoGratis2020_10)
        : 0

      ////////// OLD MUTUO GRATIS 2020
      // const MutuoGratis2020Anticipo = anticipoCustom
      //   ? anticipoCustom
      //   : valoreImmobile * MUTUO_BANCARIO_ANTICIPO
      // const MutuoGratisFascia = MUTUO_2020_FASCE.find(
      //   (fascia) => fascia.da <= valoreImmobile && fascia.a >= valoreImmobile
      // )
      // const MutuoGratis2020Bonus = MutuoGratisFascia
      //   ? MutuoGratisFascia.bonusFisso || MutuoGratisFascia.bonusVariabile * valoreImmobile
      //   : 10000
      // const MutuoGratis2020_30 = (MutuoBancarioPersonalizzatoSaldo * TASSO_30) / 1000
      // const MutuoGratis2020_25 = (MutuoBancarioPersonalizzatoSaldo * TASSO_25) / 1000
      // const MutuoGratis2020_20 = (MutuoBancarioPersonalizzatoSaldo * TASSO_20) / 1000
      // const MutuoGratis2020_15 = (MutuoBancarioPersonalizzatoSaldo * TASSO_15) / 1000
      // const MutuoGratis2020_10 = (MutuoBancarioPersonalizzatoSaldo * TASSO_10) / 1000
      // const MutuoGratis2020_30_BonusMesi = MutuoGratis2020_30
      //   ? Math.round(MutuoGratis2020Bonus / MutuoGratis2020_30)
      //   : 0
      // const MutuoGratis2020_25_BonusMesi = MutuoGratis2020_25
      //   ? Math.round(MutuoGratis2020Bonus / MutuoGratis2020_25)
      //   : 0
      // const MutuoGratis2020_20_BonusMesi = MutuoGratis2020_20
      //   ? Math.round(MutuoGratis2020Bonus / MutuoGratis2020_20)
      //   : 0
      // const MutuoGratis2020_15_BonusMesi = MutuoGratis2020_15
      //   ? Math.round(MutuoGratis2020Bonus / MutuoGratis2020_15)
      //   : 0
      // const MutuoGratis2020_10_BonusMesi = MutuoGratis2020_10
      //   ? Math.round(MutuoGratis2020Bonus / MutuoGratis2020_10)
      //   : 0
      //////////

      // MUTUO AGEVOLATO 2020
      const MutuoAgevolato2020Anticipo = anticipoCustom
        ? anticipoCustom
        : valoreImmobile * MUTUO_BANCARIO_ANTICIPO
      // const MutuoAgevolatoFascia = MUTUO_2020_FASCE.find(
      //   (fascia) => fascia.da <= valoreImmobile && fascia.a >= valoreImmobile
      // )
      // const MutuoAgevolato2020Bonus = MutuoAgevolatoFascia
      //   ? MutuoAgevolatoFascia.bonusFisso || MutuoAgevolatoFascia.bonusVariabile * valoreImmobile
      //   : 10000
      const MutuoAgevolato2020_30 = (MutuoBancarioPersonalizzatoSaldo * TASSO_30) / 1000
      const MutuoAgevolato2020_25 = (MutuoBancarioPersonalizzatoSaldo * TASSO_25) / 1000
      const MutuoAgevolato2020_20 = (MutuoBancarioPersonalizzatoSaldo * TASSO_20) / 1000
      const MutuoAgevolato2020_15 = (MutuoBancarioPersonalizzatoSaldo * TASSO_15) / 1000
      const MutuoAgevolato2020_10 = (MutuoBancarioPersonalizzatoSaldo * TASSO_10) / 1000
      const MutuoAgevolato2020_Contributo = MutuoAgevolato2020_30 * 12
      // const MutuoAgevolato2020_30_BonusMesi = MutuoAgevolato2020_30
      //   ? Math.round(MutuoAgevolato2020Bonus / (MutuoAgevolato2020_30 - rataIniziale))
      //   : 0
      // const MutuoAgevolato2020_25_BonusMesi = MutuoAgevolato2020_25
      //   ? Math.round(MutuoAgevolato2020Bonus / (MutuoAgevolato2020_25 - rataIniziale))
      //   : 0
      // const MutuoAgevolato2020_20_BonusMesi = MutuoAgevolato2020_20
      //   ? Math.round(MutuoAgevolato2020Bonus / (MutuoAgevolato2020_20 - rataIniziale))
      //   : 0
      // const MutuoAgevolato2020_15_BonusMesi = MutuoAgevolato2020_15
      //   ? Math.round(MutuoAgevolato2020Bonus / (MutuoAgevolato2020_15 - rataIniziale))
      //   : 0
      // const MutuoAgevolato2020_10_BonusMesi = MutuoAgevolato2020_10
      //   ? Math.round(MutuoAgevolato2020Bonus / (MutuoAgevolato2020_10 - rataIniziale))
      //   : 0

      // INTERESSI GRATIS
      const InteressiGratisAnticipo = anticipoCustom
        ? anticipoCustom
        : valoreImmobile * MUTUO_BANCARIO_ANTICIPO
      const InteressiGratis_30 = (MutuoBancarioPersonalizzatoSaldo * TASSO_30) / 1000
      const InteressiGratis_25 = (MutuoBancarioPersonalizzatoSaldo * TASSO_25) / 1000
      const InteressiGratis_20 = (MutuoBancarioPersonalizzatoSaldo * TASSO_20) / 1000
      const InteressiGratis_15 = (MutuoBancarioPersonalizzatoSaldo * TASSO_15) / 1000
      const InteressiGratis_10 = (MutuoBancarioPersonalizzatoSaldo * TASSO_10) / 1000
      const InteressiGratis_30_RataAgevolata = MutuoBancarioPersonalizzatoSaldo / (30 * 12)
      const InteressiGratis_25_RataAgevolata = MutuoBancarioPersonalizzatoSaldo / (25 * 12)
      const InteressiGratis_20_RataAgevolata = MutuoBancarioPersonalizzatoSaldo / (20 * 12)
      const InteressiGratis_15_RataAgevolata = MutuoBancarioPersonalizzatoSaldo / (15 * 12)
      const InteressiGratis_10_RataAgevolata = MutuoBancarioPersonalizzatoSaldo / (10 * 12)

      const InteressiGratis_30_MesiAgevolati =
        (scontoInteressiGratis * 1000) / (InteressiGratis_30 - InteressiGratis_30_RataAgevolata)
      const InteressiGratis_25_MesiAgevolati =
        (scontoInteressiGratis * 1000) / (InteressiGratis_25 - InteressiGratis_25_RataAgevolata)
      const InteressiGratis_20_MesiAgevolati =
        (scontoInteressiGratis * 1000) / (InteressiGratis_20 - InteressiGratis_20_RataAgevolata)
      const InteressiGratis_15_MesiAgevolati =
        (scontoInteressiGratis * 1000) / (InteressiGratis_15 - InteressiGratis_15_RataAgevolata)
      const InteressiGratis_10_MesiAgevolati =
        (scontoInteressiGratis * 1000) / (InteressiGratis_10 - InteressiGratis_10_RataAgevolata)

      return {
        ...state,
        strumenti: [
          {
            strumento: 'Pagamento relax', //'Mutuo bancario',
            colore: COLORE_MUTUO_BANCARIO,
            coloreBg: COLORE_MUTUO_BANCARIO_BG,
            pagina: 2,
            rate: [
              { nome: 'Anticipo', valore: MutuoBancarioAnticipo },
              { nome: '30 anni', valore: MutuoBancario30 },
              { nome: '25 anni', valore: MutuoBancario25 },
              { nome: '20 anni', valore: MutuoBancario20 },
              { nome: '15 anni', valore: MutuoBancario15 },
              { nome: '10 anni', valore: MutuoBancario10 },
            ],
          },
          {
            strumento: 'Su misura',
            colore: COLORE_MUTUO_BANCARIO,
            coloreBg: COLORE_MUTUO_BANCARIO_BG,
            pagina: 1,
            rate: [
              { nome: 'Anticipo', valore: MutuoBancarioPersonalizzatoAnticipo },
              //{ nome: 'Saldo', valore: MutuoBancarioPersonalizzatoSaldo},
              { nome: '30 anni', valore: MutuoBancarioPersonalizzato30 },
              { nome: '25 anni', valore: MutuoBancarioPersonalizzato25 },
              { nome: '20 anni', valore: MutuoBancarioPersonalizzato20 },
              { nome: '15 anni', valore: MutuoBancarioPersonalizzato15 },
              { nome: '10 anni', valore: MutuoBancarioPersonalizzato10 },
            ],
          },
          // {
          //   strumento: 'Leasing',
          //   colore: COLORE_LEASING,
          //   coloreBg: COLORE_LEASING_BG,
          //   pagina: 2,
          //   rate: [
          //     { nome: 'Anticipo', valore: LeasingAnticipo },
          //     { nome: 'Riscatto', valore: LeasingRiscatto },
          //     { nome: '20 anni', valore: Leasing30 },
          //     { nome: '15 anni', valore: Leasing25 },
          //     { nome: '10 anni', valore: Leasing20 },
          //     { nome: '5 anni', valore: Leasing15 },
          //     //{ nome: 'Leasing 3', valore: Leasing10 },
          //   ],
          // },
          {
            strumento: 'Anticipo zero',
            colore: COLORE_ANTICIPO_ZERO,
            coloreBg: COLORE_ANTICIPO_ZERO_BG,
            pagina: 2,
            rate: [
              { nome: 'Anticipo', valore: AnticipoZeroAnticipo },
              //{ nome: 'Saldo', valore: AnticipoZeroSaldo },
              { nome: '30 anni', valore: AnticipoZero30 },
              { nome: '25 anni', valore: AnticipoZero25 },
              { nome: '20 anni', valore: AnticipoZero20 },
              { nome: '15 anni', valore: AnticipoZero15 },
              { nome: '10 anni', valore: AnticipoZero10 },
            ],
          },
          // {
          //   strumento: 'Anticipo zero+',
          //   colore: COLORE_ANTICIPO_ZERO_PLUS,
          //   coloreBg: COLORE_ANTICIPO_ZERO_PLUS_BG,
          //   pagina: 2,
          //   rate: [
          //     { nome: '+84 mesi', valore: AnticipoZeroPlusAnticipo84 },
          //     //{ nome: 'Saldo', valore: AnticipoZeroPlusSaldo },
          //     { nome: '30 anni', valore: AnticipoZeroPlus30 },
          //     { nome: '25 anni', valore: AnticipoZeroPlus25 },
          //     { nome: '20 anni', valore: AnticipoZeroPlus20 },
          //     { nome: '15 anni', valore: AnticipoZeroPlus15 },
          //     { nome: '10 anni', valore: AnticipoZeroPlus10 },
          //   ],
          // },
          {
            strumento: 'Dilazioni senza banca',
            colore: COLORE_MUTUO_GRATIS,
            coloreBg: COLORE_MUTUO_GRATIS_BG,
            pagina: 2,
            rate: [
              { nome: 'Anticipo', valore: MutuoGratisAnticipo },
              //{ nome: 'Saldo', valore: MutuoGratisSaldo },
              { nome: '10 anni', valore: MutuoGratis10 },
              { nome: '8 anni', valore: MutuoGratis8 },
              { nome: '6 anni', valore: MutuoGratis6 },
              { nome: '4 anni', valore: MutuoGratis4 },
              { nome: '2 anni', valore: MutuoGratis2 },
            ],
          },
          // {
          //   strumento: 'Maxi rata finale',
          //   colore: COLORE_MAXI_RATA_FINALE,
          //   coloreBg: COLORE_MAXI_RATA_FINALE_BG,
          //   pagina: 2,
          //   rate: [
          //     { nome: 'Anticipo', valore: MaxiRataFinaleAnticipo },
          //     { nome: '2 anni', valore: 0 },
          //     { nome: '30 anni', valore: MaxiRataFinale30 },
          //     { nome: '25 anni', valore: MaxiRataFinale25 },
          //     { nome: '20 anni', valore: MaxiRataFinale20 },
          //     { nome: '15 anni', valore: MaxiRataFinale15 },
          //     //{ nome: '10 anni', valore: MaxiRataFinale10 },
          //   ],
          // },
          // {
          //   strumento: 'Rent to buy',
          //   colore: COLORE_RENT_TO_BUY,
          //   coloreBg: COLORE_RENT_TO_BUY_BG,
          //   pagina: 2,
          //   rate: [
          //     { nome: 'Mensile', valore: RentToBuyCanone },
          //     { nome: 'Saldo 1 anno', valore: RentToBuySaldo1 },
          //     { nome: 'Saldo 2 anni', valore: RentToBuySaldo2 },
          //     { nome: 'Saldo 3 anni', valore: RentToBuySaldo3 },
          //     { nome: 'Saldo 4 anni', valore: RentToBuySaldo4 },
          //     { nome: 'Saldo 5 anni', valore: RentToBuySaldo5 },
          //     //{ nome: 'Saldo anni 6', valore: RentToBuySaldo6 },
          //   ],
          // },
          // {
          //   strumento: 'Affitto riscatto',
          //   colore: COLORE_AFFITTO_RISCATTO,
          //   coloreBg: COLORE_AFFITTO_RISCATTO_BG,
          //   pagina: 2,
          //   rate: [
          //     { nome: 'Mensile', valore: AffittoRiscattoCanone },
          //     { nome: '1° blocco', valore: AffittoRiscattoBlocco1 },
          //     { nome: 'Saldo 1 anno', valore: AffittoRiscattoSaldo1 },
          //     { nome: 'Saldo 2 anni', valore: AffittoRiscattoSaldo2 },
          //     { nome: 'Saldo 3 anni', valore: AffittoRiscattoSaldo3 },
          //     { nome: '2° blocco', valore: AffittoRiscattoBlocco2 },
          //     //{ nome: '2° saldo anni 2', valore: AffittoRiscattoSaldo4 },
          //   ],
          // },
          {
            strumento: 'Mutuo rate gratis',
            colore: COLORE_MUTUO_GRATIS_2020,
            coloreBg: COLORE_MUTUO_GRATIS_2020_BG,
            pagina: 3,
            rate: [
              { nome: 'Anticipo', valore: MutuoGratis2020Anticipo },
              { nome: '30 anni', valore: MutuoGratis2020_30 },
              { nome: '25 anni', valore: MutuoGratis2020_25 },
              { nome: '20 anni', valore: MutuoGratis2020_20 },
              { nome: '15 anni', valore: MutuoGratis2020_15 },
              { nome: '10 anni', valore: MutuoGratis2020_10 },
            ],
            bonusMesi: [
              { valore: MutuoGratis2020_30_BonusMesi },
              { valore: MutuoGratis2020_25_BonusMesi },
              { valore: MutuoGratis2020_20_BonusMesi },
              { valore: MutuoGratis2020_15_BonusMesi },
              { valore: MutuoGratis2020_10_BonusMesi },
            ],
            bonusAnni: [
              { valore: MutuoGratis2020_30_BonusMesi / 12 },
              { valore: MutuoGratis2020_25_BonusMesi / 12 },
              { valore: MutuoGratis2020_20_BonusMesi / 12 },
              { valore: MutuoGratis2020_15_BonusMesi / 12 },
              { valore: MutuoGratis2020_10_BonusMesi / 12 },
            ],
          },
          {
            strumento: 'Mutuo zero pensieri', //'Mutuo agevolato',
            colore: COLORE_MUTUO_AGEVOLATO_2020,
            coloreBg: COLORE_MUTUO_AGEVOLATO_2020_BG,
            pagina: 5,
            rate: [
              { nome: 'Anticipo', valore: MutuoAgevolato2020Anticipo },
              { nome: '30 anni', valore: MutuoAgevolato2020_30 },
              { nome: '25 anni', valore: MutuoAgevolato2020_25 },
              { nome: '20 anni', valore: MutuoAgevolato2020_20 },
              { nome: '15 anni', valore: MutuoAgevolato2020_15 },
              { nome: '10 anni', valore: MutuoAgevolato2020_10 },
            ],
            contributo: MutuoAgevolato2020_Contributo,
            // bonusMesi: [
            //   { valore: MutuoAgevolato2020_30_BonusMesi },
            //   { valore: MutuoAgevolato2020_25_BonusMesi },
            //   { valore: MutuoAgevolato2020_20_BonusMesi },
            //   { valore: MutuoAgevolato2020_15_BonusMesi },
            //   { valore: MutuoAgevolato2020_10_BonusMesi },
            // ],
            // bonusAnni: [
            //   { valore: MutuoAgevolato2020_30_BonusMesi / 12 },
            //   { valore: MutuoAgevolato2020_25_BonusMesi / 12 },
            //   { valore: MutuoAgevolato2020_20_BonusMesi / 12 },
            //   { valore: MutuoAgevolato2020_15_BonusMesi / 12 },
            //   { valore: MutuoAgevolato2020_10_BonusMesi / 12 },
            // ],
          },
          {
            strumento: 'Mutuo senza interessi', //'Mutuo gratis 2.0 - Interessi gratis',
            colore: COLORE_INTERESSI_GRATIS,
            coloreBg: COLORE_INTERESSI_GRATIS_BG,
            pagina: 4,
            rate: [
              { nome: 'Anticipo', valore: InteressiGratisAnticipo },
              { nome: '30 anni', valore: InteressiGratis_30 },
              { nome: '25 anni', valore: InteressiGratis_25 },
              { nome: '20 anni', valore: InteressiGratis_20 },
              { nome: '15 anni', valore: InteressiGratis_15 },
              { nome: '10 anni', valore: InteressiGratis_10 },
            ],
            rataAgevolata: [
              { valore: InteressiGratis_30_RataAgevolata },
              { valore: InteressiGratis_25_RataAgevolata },
              { valore: InteressiGratis_20_RataAgevolata },
              { valore: InteressiGratis_15_RataAgevolata },
              { valore: InteressiGratis_10_RataAgevolata },
            ],
            mesiAgevolati: [
              {
                valore: InteressiGratis_30_MesiAgevolati,
              },
              {
                valore: InteressiGratis_25_MesiAgevolati,
              },
              {
                valore: InteressiGratis_20_MesiAgevolati,
              },
              {
                valore: InteressiGratis_15_MesiAgevolati,
              },
              {
                valore: InteressiGratis_10_MesiAgevolati,
              },
            ],
            anniAgevolati: [
              {
                valore: InteressiGratis_30_MesiAgevolati / 12,
              },
              {
                valore: InteressiGratis_25_MesiAgevolati / 12,
              },
              {
                valore: InteressiGratis_20_MesiAgevolati / 12,
              },
              {
                valore: InteressiGratis_15_MesiAgevolati / 12,
              },
              {
                valore: InteressiGratis_10_MesiAgevolati / 12,
              },
            ],
          },
        ],
      }
    }
    case CHANGE_VALORE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case RESET: {
      return {
        ...INITIAL_STATE,
      }
    }
    default:
      return state
  }
}

export const getRata = (strumento) => (globalState) => {
  const strumentoObj = globalState.calcolo.strumenti.find((s) => s.strumento === strumento)
  const anni = globalState.calcolo.anniMutuo
  if (
    strumento === 'Mutuo rate gratis' ||
    strumento === 'Mutuo zero pensieri' ||
    strumento === 'Mutuo senza interessi'
  ) {
    switch (anni) {
      case 30:
        return strumentoObj.rate[1].valore
      case 25:
        return strumentoObj.rate[2].valore
      case 20:
        return strumentoObj.rate[3].valore
      case 15:
        return strumentoObj.rate[4].valore
      case 10:
        return strumentoObj.rate[5].valore
      default:
        return 0
    }
  }

  return 0
}

export const getBonusAnni = (strumento) => (globalState) => {
  const strumentoObj = globalState.calcolo.strumenti.find((s) => s.strumento === strumento)
  const anni = globalState.calcolo.anniMutuo
  if (strumento === 'Mutuo rate gratis') {
    // || 'Mutuo zero pensieri') {
    switch (anni) {
      case 30:
        return strumentoObj.bonusAnni[0].valore
      case 25:
        return strumentoObj.bonusAnni[1].valore
      case 20:
        return strumentoObj.bonusAnni[2].valore
      case 15:
        return strumentoObj.bonusAnni[3].valore
      case 10:
        return strumentoObj.bonusAnni[4].valore
      default:
        return 0
    }
  }

  return 0
}

export const getBonusMesi = (strumento) => (globalState) => {
  const strumentoObj = globalState.calcolo.strumenti.find((s) => s.strumento === strumento)

  const anni = globalState.calcolo.anniMutuo
  if (strumento === 'Mutuo rate gratis') {
    // || 'Mutuo zero pensieri') {
    switch (anni) {
      case 30:
        return strumentoObj.bonusMesi[0].valore
      case 25:
        return strumentoObj.bonusMesi[1].valore
      case 20:
        return strumentoObj.bonusMesi[2].valore
      case 15:
        return strumentoObj.bonusMesi[3].valore
      case 10:
        return strumentoObj.bonusMesi[4].valore
      default:
        return 0
    }
  }
  return 0
}

export const getContributo = (strumento) => (globalState) => {
  const strumentoObj = globalState.calcolo.strumenti.find((s) => s.strumento === strumento)

  const anni = globalState.calcolo.anniMutuo
  if (strumento === 'Mutuo zero pensieri') {
    switch (anni) {
      case 30:
        return strumentoObj.contributo
      default:
        return 0
    }
  }

  return 0
}

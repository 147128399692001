import { combineReducers } from 'redux';
import calcoloReducer from './reducer_calcolo';
import paginaReducer from './reducer_pagina';

const rootReducer = combineReducers({
  calcolo: calcoloReducer,
  pagina: paginaReducer
});

export default rootReducer;

import React, { Component } from 'react'
import { connect } from 'react-redux'
import numeral from 'numeral'

import { ricalcolo, changeValore, changePagina, reset } from './actions'
// import Mutuo2020 from './Mutuo2020'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { authenticated: false, username: '', password: '' }
  }

  changeValoreImmobile(event) {
    this.props.changeValore({ valoreImmobile: event.target.value })
    this.props.ricalcolo()
  }

  resetValoreImmobile() {
    this.props.reset()
    this.props.ricalcolo(0)
  }

  changeAnticipoCustom(event) {
    this.props.changeValore({ anticipoCustom: event.target.value })
    this.props.ricalcolo()
  }

  resetAnticipoCustom() {
    this.props.changeValore({ anticipoCustom: '' })
    this.props.ricalcolo()
  }

  changeScontoInteressiGratis(event) {
    this.props.changeValore({ scontoInteressiGratis: event.target.value })
    this.props.ricalcolo()
  }

  changeRataIniziale(event) {
    this.props.changeValore({ rataIniziale: event.target.value })
    this.props.ricalcolo()
  }

  resetRataIniziale() {
    this.props.changeValore({ rataIniziale: '' })
    this.props.ricalcolo()
  }

  renderNomiRate(rate) {
    return rate.map((rata) => <th key={rata.nome}>{rata.nome}</th>)
  }

  renderValoriRate(rate, decimal = false) {
    return rate.map((rata, index) => (
      <td className="valore" key={index}>
        {decimal ? (
          <div>{numeral(rata.valore).format('0,0.00')}</div>
        ) : (
          <div>{numeral(rata.valore).format('0,0')}</div>
        )}
      </td>
    ))
  }

  render() {
    if (!this.state.authenticated) {
      return (
        <div className="container mx-auto" style={{ maxWidth: 500 }}>
          <div className="logo">
            <h1>Paga come vuoi</h1>
          </div>
          <div className="form pt-3 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              value={this.state.username}
              onChange={(event) => this.setState({ username: event.target.value })}
            />
          </div>
          <div className="form mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={this.state.password}
              onChange={(event) => this.setState({ password: event.target.value })}
            />
          </div>
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              if (this.state.username === 'mutuogratis' && this.state.password === '12345') {
                this.setState({ authenticated: true })
              }
            }}
          >
            Accedi
          </button>
        </div>
      )
    }
    return (
      <div className="container-fluid">
        <div className="logo">
          <h1>Paga come vuoi</h1>
        </div>
        <div className="valore-immobile">
          <div className="form-inline">
            <input
              type="text"
              className="form-control"
              placeholder="Valore immobile"
              value={this.props.valoreImmobile}
              onChange={this.changeValoreImmobile.bind(this)}
              onClick={this.resetValoreImmobile.bind(this)}
            />
          </div>
        </div>
        {(this.props.pagina === 1 ||
          this.props.pagina === 3 ||
          this.props.pagina === 4 ||
          this.props.pagina === 5) && (
          <div className="valore-immobile">
            <div className="form-inline">
              <input
                type="text"
                className="form-control"
                placeholder="Anticipo"
                value={this.props.anticipoCustom}
                onChange={this.changeAnticipoCustom.bind(this)}
                onClick={this.resetAnticipoCustom.bind(this)}
              />
            </div>
            {(this.props.pagina === 3 || this.props.pagina === 4) && (
              <input
                type="number"
                className="form-control hidden-input"
                defaultValue={10}
                value={this.props.scontoInteressiGratis}
                onChange={this.changeScontoInteressiGratis.bind(this)}
              />
            )}
          </div>
        )}
        {this.props.pagina === 6 && (
          <div className="valore-immobile">
            <div className="form-inline">
              <input
                type="text"
                className="form-control"
                placeholder="Contributo rata"
                value={this.props.rataIniziale}
                onChange={this.changeRataIniziale.bind(this)}
                onClick={this.resetRataIniziale.bind(this)}
              />
            </div>
          </div>
        )}

        {this.props.valoreImmobile && (
          <div className="pagine">
            <button className="btn btn-primary" onClick={() => this.props.changePagina(1)}>
              1
            </button>
            &nbsp;
            <button className="btn btn-primary" onClick={() => this.props.changePagina(2)}>
              2
            </button>
            &nbsp;
            <button className="btn btn-primary" onClick={() => this.props.changePagina(3)}>
              3
            </button>
            &nbsp;
            <button className="btn btn-primary" onClick={() => this.props.changePagina(4)}>
              4
            </button>
            &nbsp;
            <button className="btn btn-primary" onClick={() => this.props.changePagina(5)}>
              5
            </button>
            {/* &nbsp;
            <button className="btn btn-primary" onClick={() => this.props.changePagina(6)}>
              6
            </button> */}
          </div>
        )}

        {this.props.valoreImmobile &&
          this.props.strumenti.map((strumento) => {
            if (strumento.pagina === this.props.pagina) {
              return (
                <div className="strumento" key={strumento.strumento}>
                  <h2 style={{ color: strumento.colore }}>{strumento.strumento}</h2>

                  <table className="table" style={{ backgroundColor: strumento.coloreBg }}>
                    <thead>
                      <tr style={{ color: strumento.colore }}>
                        {this.renderNomiRate(strumento.rate)}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>{this.renderValoriRate(strumento.rate)}</tr>
                      {strumento.bonusMesi && (
                        <tr
                          style={{
                            borderTop: `2px solid ${strumento.colore}`,
                            borderBottom: `2px solid ${strumento.colore}`,
                          }}
                        >
                          <td
                            colSpan={strumento.rate.length === 7 ? 2 : 1}
                            style={{ color: strumento.colore }}
                          >
                            Bonus mesi
                          </td>
                          {this.renderValoriRate(strumento.bonusMesi)}
                        </tr>
                      )}
                      {strumento.bonusAnni && (
                        <tr>
                          <td
                            colSpan={strumento.rate.length === 7 ? 2 : 1}
                            style={{ color: strumento.colore }}
                          >
                            Bonus anni
                          </td>
                          {this.renderValoriRate(strumento.bonusAnni, true)}
                        </tr>
                      )}
                      {strumento.contributo && (
                        <tr
                          style={{
                            borderTop: `2px solid ${strumento.colore}`,
                          }}
                        >
                          <td style={{ color: strumento.colore }}>Contributo</td>
                          <td className="valore">
                            <div>{numeral(strumento.contributo).format('0,0')}</div>
                          </td>
                        </tr>
                      )}
                      {strumento.rataAgevolata && (
                        <tr
                          style={{
                            borderTop: `2px solid ${strumento.colore}`,
                          }}
                        >
                          <td
                            colSpan={strumento.rate.length === 7 ? 2 : 1}
                            style={{ color: strumento.colore }}
                          >
                            Rata agevolata (senza interessi)
                          </td>
                          {this.renderValoriRate(strumento.rataAgevolata)}
                        </tr>
                      )}
                      {strumento.mesiAgevolati && (
                        <tr
                          style={{
                            borderTop: `2px solid ${strumento.colore}`,
                          }}
                        >
                          <td
                            colSpan={strumento.rate.length === 7 ? 2 : 1}
                            style={{ color: strumento.colore }}
                          >
                            Mesi agevolati
                          </td>
                          {this.renderValoriRate(strumento.mesiAgevolati)}
                        </tr>
                      )}
                      {strumento.anniAgevolati && (
                        <tr>
                          <td
                            colSpan={strumento.rate.length === 7 ? 2 : 1}
                            style={{ color: strumento.colore }}
                          >
                            Anni agevolati
                          </td>
                          {this.renderValoriRate(strumento.anniAgevolati, true)}
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {/* {this.props.pagina === 4 && <Mutuo2020 strumento="Mutuo gratis" />} */}

                  {/* {this.props.pagina === 6 && (
                    <Mutuo2020
                      // strumento="Mutuo agevolato"
                      //strumento="Mutuo zero pensieri"
                    />
                  )} */}
                </div>
              )
            }
            return null
          })}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    strumenti: state.calcolo.strumenti,
    valoreImmobile: state.calcolo.valoreImmobile,
    anticipoCustom: state.calcolo.anticipoCustom,
    rataIniziale: state.calcolo.rataIniziale,
    pagina: state.pagina,
  }
}

export default connect(mapStateToProps, {
  ricalcolo,
  changeValore,
  changePagina,
  reset,
})(App)
